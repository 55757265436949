import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import CMSImage from '../components/UI/CMSImage'


const MaintenancePage = () => {
  const data = useStaticQuery(graphql`
    query maintenancePageQuery {
        allContentfulGenericHeadingTextAndIcon(filter: {contentIdentifier: {eq: "MaintenancePage"}}) {
          nodes {
            icon {
              gatsbyImageData(placeholder:NONE)
              title
              file {
                contentType
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            header {
              childMarkdownRemark {
                html
              }
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            contentIdentifier
          }
        }
      }        
    `)

  const pageData = data.allContentfulGenericHeadingTextAndIcon.nodes[0];
      
  return (
    <>

      <main>
        <ContentWrapper>
          <div className="col heroImg">
          <CMSImage
              svg={pageData.icon.svg}
              gatsbyImageData={pageData.icon?.gatsbyImageData}
              file={pageData.icon.file}
              alt={pageData.icon.title}
            />
          </div>
          <div
              dangerouslySetInnerHTML={{
                __html: pageData.header.childMarkdownRemark.html,
              }}
            />
          <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
        </ContentWrapper>
      </main>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  h2 {
    margin-bottom: 24px;
    max-width: 680px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin: 0;
    max-width: 680px;
    text-align: center;
  }
  .heroImg {
    width: 100%;
    max-width: 320px;
  }
  .col {
    margin-bottom: 40px;
  }
`


export default MaintenancePage
